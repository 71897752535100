import React, { Component } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../../assets/css/animate.css";
import BlogThumb from "../../../../assets/images/event/ed-thumb.png";
import GallaryIem3 from "../../../../assets/images/gallary/e-gallary-l1.png";
import GallaryIem4 from "../../../../assets/images/gallary/e-gallary-l2.png";
import GallaryIem2 from "../../../../assets/images/gallary/e-gallary-sm1.png";
import GallaryIem1 from "../../../../assets/images/gallary/e-gallary1.png";
// Gallary View image import
import GallaryView1 from "../../../../assets/images/gallary/view1.png";
import GallaryView2 from "../../../../assets/images/gallary/view2.png";
// reply button icon
import ReplyBtnIcon from "../../../../assets/images/icons/reply-icon.png";
// commentor image import
import Commentor1 from "../../../../assets/images/speaker/commentor-1.png";
import Commentor2 from "../../../../assets/images/speaker/commentor-2.png";
import Commentor3 from "../../../../assets/images/speaker/commentor-3.png";
// main speaker image import
import MainSeaker1 from "../../../../assets/images/speaker/event-sm1.png";
import MainSeaker2 from "../../../../assets/images/speaker/event-sm2.png";
import MainSeaker3 from "../../../../assets/images/speaker/event-sm3.png";
import MainSeaker4 from "../../../../assets/images/speaker/event-sm4.png";
import MainSeaker5 from "../../../../assets/images/speaker/event-sm5.png";
import MainSeaker6 from "../../../../assets/images/speaker/event-sm6.png";
// speaker image import
import speaker1 from "../../../../assets/images/speaker/speaker-sm1.png";
import speaker2 from "../../../../assets/images/speaker/speaker-sm2.png";
import { SRLWrapper } from "simple-react-lightbox";
import speaker3 from "../../../../assets/images/speaker/speaker-sm3.png";
import speaker4 from "../../../../assets/images/speaker/speaker-sm4.png";
// main sponser logo image import
import sponsorLogo1 from "../../../../assets/images/sponsor/ed-sponsor1.png";
import sponsorLogo2 from "../../../../assets/images/sponsor/ed-sponsor2.png";
import sponsorLogo3 from "../../../../assets/images/sponsor/ed-sponsor3.png";
import sponsorLogo4 from "../../../../assets/images/sponsor/ed-sponsor4.png";

import { useEffect } from 'react';

export default function EventDetailsLeftArea(params)
{
  const cme = params.cme;

  useEffect(() =>
  {
    new WOW.WOW().init()

  }, []);

  const scrollTop = () =>
  {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return cme && (
    <>
      <div className="col-xl-8">
        <div className="ed-main-wrap">
          <div className="ed-top">
            <div className="ed-thumb">
              <img src={BlogThumb} alt="Blog details" />
            </div>
            <ul className="ed-status">
              <li>
                <i className="bi bi-calendar2-week" />
                {cme.startDate}
                -
                {cme.endDate}

              </li>
              <li>
                <i className="bi bi-pin-map" />
                <div style={{ display: 'inline-flex', flexDirection: 'row' }}>
                  {cme.location}<br /> {cme.cmeCity}
                </div>
              </li>
            </ul>
            {/* <div className="event-info row align-items-center">
                <div className="col-lg-3 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-blockquote-left" />
                    </div>
                    <div className="info-content">
                      <h5>Event Type</h5>
                      <p>Web Development</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-megaphone" />
                    </div>
                    <div className="info-content">
                      <h5>Speaker</h5>
                      <p>10 Best Speaker</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-lightning" />
                    </div>
                    <div className="info-content">
                      <h5>Sponsor</h5>
                      <p>Event Lab</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="event-rating text-center">
                    <ul className="d-flex justify-content-center">
                      <li>
                        <i className="bi bi-star-fill" />
                      </li>
                      <li>
                        <i className="bi bi-star-fill" />
                      </li>
                      <li>
                        <i className="bi bi-star-fill" />
                      </li>
                      <li>
                        <i className="bi bi-star-fill" />
                      </li>
                      <li>
                        <i className="bi bi-star-half" />
                      </li>
                    </ul>
                    <h6>(500)</h6>
                  </div>
                </div>
              </div> */}
          </div>
          <div className="ed-tabs-wrapper">
            {/* <h5>CME Details</h5> */}
            {/* <div className="tabs-pill">
                <ul
                  className="nav nav-pills justify-content-center"
                  id="pills-tab2"
                  role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-details-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-details"
                      type="button"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true">
                      {" "}
                      <i className="bi bi-info-circle" /> <span>Details</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-schedule-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-schedule"
                      type="button"
                      role="tab"
                      aria-controls="pills-schedule"
                      aria-selected="false">
                      <i className="bi bi-calendar3" /> <span>Schedule</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-gallary-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-gallary"
                      type="button"
                      role="tab"
                      aria-controls="pills-gallary"
                      aria-selected="false">
                      <i className="bi bi-images" /> <span>Gallery</span>
                    </button>
                  </li>
                </ul>
              </div> */}

            <div className="tab-content" id="pills-tabContent2">
              <div
                className="tab-pane fade show active"
                id="pills-details"
                role="tabpanel"
                aria-labelledby="pills-details-tab">
                <div className="details-tab-content">
                  <h3 className="ed-title">
                    {cme.cmeName}
                  </h3>
                  <p>
                    {cme.aboutCME}
                  </p>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div className="details-tab-content row">
                <strong style={{ marginBottom: '10px' }}>Schedule</strong>
                {cme.schedules.map((schedule) =>
                {
                  return <>
                    <div className="col-4" style={{ backgroundColor: '#eee', padding: '0px', boxShadow: '4px 4px 16px #0005', borderRadius: '15px', overflow: 'hidden' }}>
                      <div style={{padding:'5px', background: '#444', color:'#ddd', textAlign:'center', fontSize:'larger', fontWeight:'600'}}>
                        {new Date(schedule.startDate).toLocaleDateString()}-{new Date(schedule.endDate).toLocaleDateString()}
                      </div>
                        
                      <div style={{ padding: '10px' }}>
                        <br />
                        <strong>{schedule.speakerCodeNumber}{schedule.speakerName}</strong><br />
                        <span style={{ fontSize: '0.9rem', color: '#555' }}>{schedule.speaerDescription}</span><br />
                        {schedule.speakerTopic}<br />
                        <span style={{ fontSize: '0.8rem', color: '#444' }}> {schedule.speakerTopicDescription.substring(0, 200)}</span><br />
                      </div>
                    </div>

                  </>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
