import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { get, post } from '../../../web-client';
import Breadcrumb from "./Breadcrumb";

export default function AddCME()
{
    const history = useHistory();
    const [isEditing, setEditing] = useState(false);

    const [cme, updateCme] = useState(
        {
            "id": "",
            "cmeId": "",
            "cmeName": "",
            "cmeCity": "",
            "startDate": "",
            // "startTime": null,
            "organizerId": 0,
            "cmePoints": 0,
            "cmeFees": 0,
            "aboutCME": "",
            "location": "",
            "image": "",
            "bankDetailsId": 0,
            "organizer": {
                "contactSalutation": "",
                "contactName": "",
                "contactPhone": "",
                "contactEmail": "",
                "organizationName": ""
            },
            "bankAccount": {
                "accountName": "",
                "accountNumber": "",
                "bankName": "",
                "ifscCode": ""
            },
            "schedules": []
        }
    );

    const schedule = {
        "speakerCodeNumber": "",
        "speakerTopic": "",
        "speakerTopicDescription": "",
        "speakerName": "",
        "startDate": "",
        "endDate": "",
        // "startTime": null,
        // "endTime": null
    };

    const updateSchedule = (oldSchedule, newSchedule) =>
    {
        let index = cme.schedules.indexOf(oldSchedule);
        const schedules = [...cme.schedules];
        schedules[index] = newSchedule;
        updateCme({ ...cme, schedules: schedules });
    };

    const addSchedule = (e) =>
    {
        e.preventDefault();
        updateCme({ ...cme, schedules: [...cme.schedules, { ...schedule }] });
    };

    const removeSchedule=(s)=>
    {
        // eslint-disable-next-line no-restricted-globals
        if(!confirm('Do you want to remove this schedule?')) return;
        const index=cme.schedules.indexOf(s);
        const schedules=[...cme.schedules];
        schedules.splice(index,1);
        updateCme({ ...cme, schedules: schedules });
    }

    const registerCME = (e) =>
    {
        e.preventDefault();
        cme.cmeId=window.crypto.randomUUID();
        post('/api/CME/admin/cme/add', cme).then((res) =>
        {
            history.push("/event");
        });
    };

    return <>
        <Breadcrumb isEditing={isEditing} />

        <div className="col-12">
            <div className="event-d-sidebar">
                <div className="event-book-form">
                    <div className="category-title">
                        <i className="bi bi-bookmark-check" />{isEditing ? "Edit CME" : "Add CME"} <h4></h4>
                    </div>
                    <form action="#" id="event-book" className="event-book">
                        <div className="primary-input-group">
                            <input type="text" placeholder="Name of CME" onChange={(e) => updateCme({ ...cme, cmeName: e.target.value })} />
                            <textarea placeholder="Description" onChange={(e) => updateCme({ ...cme, aboutCME: e.target.value })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="date" placeholder="From Date" onChange={(e) => updateCme({ ...cme, startDate: e.target.value })} />
                            {/* <input type="time" placeholder="Time" onChange={(e) => updateCme({ ...cme, startTime: e.target.value })} /> */}
                        </div>
                        {/* <div className="primary-input-group">
                            <input type="text"  placeholder="Type of CME"  onChange={(e) => updateCme({ ...cme, type:e.target.value })}/>
                        </div> */}
                        <div className="primary-input-group">
                            <input type="text" placeholder="location" onChange={(e) => updateCme({ ...cme, location: e.target.value })} />
                            <input type="text" placeholder="City" onChange={(e) => updateCme({ ...cme, cmeCity: e.target.value })} />
                            {/* <input type="text"  placeholder="City" />
                            <input type="text"  placeholder="State" />
                            <input type="text"  placeholder="Pincode" /> */}
                        </div>
                        <div className="primary-input-group">
                            <input type="number" placeholder="Points" onChange={(e) => updateCme({ ...cme, cmePoints: parseInt(e.target.value) })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="number" placeholder="Fees" onChange={(e) => updateCme({ ...cme, cmeFees: parseInt(e.target.value) })} />
                        </div>
                        {/* <div className="primary-input-group">
                            <input type="text" id="lname" placeholder="CME Code"  onChange={(e) => updateCme({ ...cme, code:e.target.value })}/>
                        </div> */}
                        {/* <div className="primary-input-group">
                            <input type="number" id="lname" placeholder="Total Seats"  onChange={(e) => updateCme({ ...cme, seat:e.target.value })}/>
                        </div> */}
                        <hr />
                        <strong>Organizer Contact</strong>
                        <div className="primary-input-group">
                            <input type="text" placeholder="Salutation" onChange={(e) => updateCme({ ...cme, organizer: { ...cme.organizer, contactSalutation: e.target.value } })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="text" placeholder="Name" onChange={(e) => updateCme({ ...cme, organizer: { ...cme.organizer, contactName: e.target.value } })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="text" placeholder="Phone" onChange={(e) => updateCme({ ...cme, organizer: { ...cme.organizer, contactPhone: e.target.value } })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="text" placeholder="Email" onChange={(e) => updateCme({ ...cme, organizer: { ...cme.organizer, contactEmail: e.target.value } })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="text" placeholder="Organization Name" onChange={(e) => updateCme({ ...cme, organizer: { ...cme.organizer, organizationName: e.target.value } })} />
                        </div>
                        <hr />
                        <strong>Bank Account Details</strong>
                        <div className="primary-input-group">
                            <input type="text" placeholder="Account Name" onChange={(e) => updateCme({ ...cme, bankAccount: { ...cme.bankAccount, accountName: e.target.value } })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="text" placeholder="Account Number" onChange={(e) => updateCme({ ...cme, bankAccount: { ...cme.bankAccount, accountNumber: e.target.value } })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="text" placeholder="Bank Name" onChange={(e) => updateCme({ ...cme, bankAccount: { ...cme.bankAccount, bankName: e.target.value } })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="text" placeholder="IFSC" onChange={(e) => updateCme({ ...cme, bankAccount: { ...cme.bankAccount, ifscCode: e.target.value } })} />
                        </div>
                        <hr />
                        <strong>Schedules</strong>
                        {cme.schedules.map(schedule => <>
                            <div className="card">
                                <div className="primary-input-group">
                                    <input type="text" placeholder="Speaker Name" onChange={(e) => updateSchedule(schedule, { ...schedule, speakerName: e.target.value })} />
                                </div>
                                <div className="primary-input-group">
                                    <input type="text" placeholder="Topic" onChange={(e) => updateSchedule(schedule, { ...schedule, speakerTopic: e.target.value })} />
                                </div>
                                <div className="primary-input-group">
                                    <input type="text" placeholder="Desciption" onChange={(e) => updateSchedule(schedule, { ...schedule, speakerTopicDescription: e.target.value })} />
                                </div>
                                <div className="primary-input-group">
                                    <input type="date" placeholder="Start Date" onChange={(e) => updateSchedule(schedule, { ...schedule, startDate: e.target.value })} />
                                    {/* <input type="text" placeholder="Start Time" onChange={(e) => updateSchedule(schedule, { ...schedule, startTime: e.target.value })} /> */}
                                </div>
                                <div className="primary-input-group">
                                    <input type="date" placeholder="End Date" onChange={(e) => updateSchedule(schedule, { ...schedule, endDate: e.target.value })} />
                                    {/* <input type="text" placeholder="End Time" onChange={(e) => updateSchedule(schedule, { ...schedule, endTime: e.target.value })} /> */}
                                </div>
                                <div className="row" style={{flexDirection:'row-reverse'}}>
                                    <div className="col-1" style={{textAlign:'center'}}>
                                        <i onClick={()=>{removeSchedule(schedule)}} className="bi-trash-fill"></i>
                                    </div>
                                </div>
                            </div>
                        </>)}
                        <button type="submit" className="primary-submit d-block w-10" style={{ transform: 'scale(0.8)' }} onClick={addSchedule}>Add Schedule</button>
                        <div className="submit-btn">
                            <button onClick={registerCME}
                                type="submit"
                                className="primary-submit d-block w-100">
                                Add CME
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        {JSON.stringify(cme)}

    </>;
}