import React, { Component } from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb(props) {
  const scrollTop=()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  
    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                  <div className="page-outlined-text">
                    <h1> {props.isEditing?"Edit CME":"Add CME"} </h1>
                  </div>
                  <h2 className="page-title">{props.isEditing?"Edit CME":"Add CME"}</h2>
                  <ul className="page-switcher">
                    <li>
                      <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                        Home <i className="bi bi-caret-right" />
                      </Link>
                    </li>
                    <li>{props.isEditing?"Edit CME":"Add CME"}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
      </>
    );
  
}
