import BreadCrumb from "./BreadCrumb";

export default function Certificates(){
    return <>
      <BreadCrumb/>
      <div className="col-12">
            <div className="event-d-sidebar">
                <div className="event-book-form">
                    <div className="category-title">
                        <i className="bi bi-bookmark-check" /> <h4>Your Certificates</h4>
                    </div>
                    <div className="row">
                        <div className="col-4 card" style={{padding:'50px', borderRadius:'15px'}}>
                            Name of CME<br/>
                            Date<br/><br/>

                            <button type="submit" className="primary-submit d-block w-100">Download Certificate</button>

                        </div>
                        <div className="col-4 card" style={{padding:'50px', borderRadius:'15px'}}>
                            Name of CME<br/>
                            Date<br/><br/>

                            <button type="submit" className="primary-submit d-block w-100">Download Certificate</button>
 
                        </div>
                        <div className="col-4 card" style={{padding:'50px', borderRadius:'15px'}}>
                            Name of CME<br/>
                            Date<br/><br/>

                            <button type="submit" className="primary-submit d-block w-100">Download Certificate</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}