import { useState, useEffect } from "react";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import BreadCrumb from './BreadCrumb';
import { get, post } from '../../../web-client';

export default function Login(){

    const [user, updateUser] = useState({
        "email": "",
        "password": "",
    });

    const history=useHistory();
    
    const login=(e)=>{
        e.preventDefault();
        post('/api/Auth/user/login',user).then(async (res)=>{
            const response=await res.json();
            console.log(response);
            window.localStorage.setItem('email',response.email);
            history.push('/Home');            
        });        
    };

    return <>
        <BreadCrumb />
        <div className="col-12">
            <div className="event-d-sidebar">
                <div className="event-book-form">
                    <div className="category-title">
                        <i className="bi bi-bookmark-check" /> <h4>Login</h4>
                    </div>
                    <form onSubmit={login} id="event-book" className="event-book">
                        <div className="primary-input-group">
                            <input type="email" id="e-email" placeholder="Your Email"  onChange={(e) => updateUser({ ...user, email: e.target.value })} />
                        </div>
                        <div className="primary-input-group">
                            <input id="lname" placeholder="Password" type="password" onChange={(e) => updateUser({ ...user, password: e.target.value })} />
                        </div>
                        <div className="submit-btn">
                            <button onClick={login}
                                type="submit"
                                className="primary-submit d-block w-100">
                                Login
                            </button>
                        </div>
                    </form>
                    New user? <Link to="/register">Register here</Link>
                </div>
            </div>
        </div>
    </>;
}
