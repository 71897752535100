import { useState, useEffect } from "react";
import { useHistory, useParams, Link } from 'react-router-dom';
import { get, post } from '../../../../web-client';
import BreadCrumb from "./BreadCrumb";

export default function RegisterForCme(params)
{
    const [cme, updateCme] = useState(null);

    const [reg, updateReg] = useState(
        {
            "userId": "",
            "registrationDate": "",
            "registrationFee": 0,
            "paymentTransactionId": ""
        });

    const history = useHistory();

    const { id } = useParams();

    useEffect(() =>
    {
        const email = window.localStorage.getItem('email');
        if (email === null)
        {
            history.push('/login');
            return;
        }
        get('/api/CME/cme/' + id).then(async (res) =>
        {
            const cme = await res.json();
            updateCme(cme);
            updateReg({ ...reg, registrationFee: cme.cmeFees, registrationDate: new Date().toLocaleDateString() });
        });
    }, [id]);

    const registerForCme = (e) =>
    {
        e.preventDefault();
        const email = window.localStorage.getItem('email');
        const reg2={ ...reg, userId: email };
        updateReg(reg2);
        post('/api/CMERegistration/register?cmeId=' + id, reg2).then(async (res) =>
        {
            const response = await res.json();
            console.log(response);
        });
    };


    return cme && (<>
        <BreadCrumb />
        <div className="event-d-sidebar">
            <div className="event-book-form">
                <div className="category-title">
                    <i className="bi bi-bookmark-check" /> <h4>Register for CME : {cme.cmeName}</h4>
                </div>
                <form onSubmit={registerForCme} id="event-book" className="event-book">

                    {cme.cmeFees > 0 ? <>
                        Fees : ₹{cme.cmeFees}<br />
                        <strong>Banking Details</strong>
                        <br />
                        Account Name : {cme.bankAccount.accountName}<br />
                        Account Number : {cme.bankAccount.accountNumber}<br />
                        Bank Name : {cme.bankAccount.bankName}<br />
                        IFSC : {cme.bankAccount.ifscCode}<br />
                        <div className="primary-input-group">
                            <input type="text" id="e-email" placeholder="Transaction ID" onChange={(e) => updateReg({ ...reg, paymentTransactionId: e.target.value })} />
                        </div>
                    </> : <>
                        You can register for this CME for FREE
                    </>}
                    <div className="submit-btn">
                        <button
                            type="submit"
                            className="primary-submit d-block w-100">
                            Register for this CME
                        </button>
                    </div>
                </form>
            </div>
        </div>    </>);
}