import React, { Component } from "react";
import BreadCrumb from "./BreadCrumb";
import EventDetailsWrapper from "./EventDetailsWrapper";
import RecentEvent from "./RecentEvent";

import { useHistory, useParams } from 'react-router-dom';
import { get, post } from '../../../../web-client';
import { useState, useEffect } from "react";


export default function EventDetails (props) {

  const { id }=useParams();
  const [cme, updateCme]=useState(null);

  useEffect(()=>{
    get('/api/CME/cme/'+id).then(async (res)=>{
      updateCme(await res.json());
    });
  },[id]);

    return (
      <>
        <BreadCrumb />
        <EventDetailsWrapper cme={cme} />
        {/* <RecentEvent /> */}
      </>
    );
}

// export default EventDetails;
