import React, { Component } from "react";
import EventDetailsLeftArea from "./EventDetailsLeftArea";
import EventRightArea from "./EventRightArea";

export default function EventDetailsWrapper(params)
{

  return (
    <>
      <div className="event-details-wrapper ">
        <div className="container pt-120 position-relative">
          <div className="background-title text-style-one">
            <h2>Event Details</h2>
          </div>
          <div className="row">
            {/* event detils child componet */}
            <EventDetailsLeftArea cme={params.cme} />
            <EventRightArea cme={params.cme} />

          </div>
        </div>
      </div>
    </>
  );
}
