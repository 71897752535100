import BreadCrumb from "./BreadCrumb";

export default function ChangePassword() {
    return <>
        <BreadCrumb />
        <div className="col-12">
            <div className="event-d-sidebar">
                <div className="event-book-form">
                    <div className="category-title">
                        <i className="bi bi-bookmark-check" /> <h4>Recover Password</h4>
                    </div>
                    <form action="#" id="event-book" className="event-book">
                        <div className="primary-input-group">
                            <input type="email" id="e-email" placeholder="Your Email" />
                        </div>
                        <div className="primary-input-group">
                            <button type="submit" className="primary-submit d-block w-100">Get OTP</button>
                        </div>
                        <div className="primary-input-group">
                            <input id="lname" placeholder="OTP" type="password" />
                        </div>
                        <div className="primary-input-group">
                            <input id="lname" placeholder="Password" type="password" />
                            <input id="lname" placeholder="Retype Password" type="password" />
                        </div>
                        <div className="submit-btn">
                            <button
                                type="submit"
                                className="primary-submit d-block w-100">
                                Change Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>;
}