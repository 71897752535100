import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventBanner from "../../../../assets/images/banners/sb-banner.png";
import OrgabizerIMG from "../../../../assets/images/event/event-orgainizer.png";
// recent event images import
import eventThemnIMG1 from "../../../../assets/images/event/event-thumb-sm1.png";
import eventThemnIMG2 from "../../../../assets/images/event/event-thumb-sm2.png";
import eventThemnIMG3 from "../../../../assets/images/event/event-thumb-sm3.png";
import eventThemnIMG4 from "../../../../assets/images/event/event-thumb-sm4.png";
import SignatureImg from "../../../../assets/images/event/signature.png";

import { useEffect } from 'react';
import { useHistory} from 'react-router-dom';

export default function EventRightArea(params)
{
  const cme=params.cme;

  const history=useHistory();

  const scrollTop = () =>
  {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const registerForCme=()=>{
    const email=window.localStorage.getItem("email");
    if(email===null)
      {
        window.localStorage.setItem('cmeId',cme.cmdId);
        history.push('/login');
      }
      else
      {
        history.push('/registerforcme/'+cme.cmeId)
      }
  };
 
  return cme && (
    <>
      <div className="col-xl-4">
        <div className="event-d-sidebar">
          <div className="submit-btn">
            <button
              onClick={registerForCme}
              type="submit"
              className="primary-submit d-block w-100">
              Register for this CME
            </button>
          </div>
          <div className="event-organizer-wrap">
            <div className="category-title">
              <i className="bi bi-person-plus" /> <h4>Event Organized By</h4>
            </div>
            <div className="organizer-info">
              {/* <div className="organizer-image">
                <img src={OrgabizerIMG} alt="Imgs" />
              </div> */}
              <h4>{cme.organizer.organizationName}</h4>
              {/* <h5></h5> */}
              {/* <div className="organizer-signature">
                <img src={SignatureImg} alt="Imgs" />
              </div> */}
              <h6>Contact</h6>
              <div>{cme.organizer.contactSalutation} {cme.organizer.contactName}</div>
              <div>{cme.organizer.contactPhone}</div>
              <div>{cme.organizer.contactEmail}</div>
            </div>
          </div>
          {/* <div className="event-sidebar-banner">
              <Link onClick={this.scrollTop} to={"#"}>
                <img src={EventBanner} alt="Imgs" className="img-fluid" />
              </Link>
            </div> */}
        </div>
      </div>
    </>
  );
}

