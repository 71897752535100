const BASE_URL='https://cme.absolutemarketresearch.com';

function get(url)
{
    return fetch(`${BASE_URL}${url}`,{
        method: "GET"
    });
}

function post(url,data)
{
    const body=JSON.stringify(data);
    console.log(body);
    
    return fetch(`${BASE_URL}${url}`,{
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body: body
    });
}

export {get, post}