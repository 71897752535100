import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { get, post } from '../../../web-client';

export default function Register() {

    const [user, updateUser] = useState({
        // "id": "string",
        "prefix": "",
        "firstName": "",
        "lastName": "",
        "specialization": "",
        "mobile": "",
        "emailId": "",
        "password": "",
        "confirmPassword": "",
        "userRole": "user",
        // "resetPasswordToken": "string"
    });

    useEffect(() => {
        // Code to run when the component has loaded
        // getAllUsers();
      }, []);

    // console.log('a');
    // console.log(user);

    // const getAllUsers = (e) => {
    //     if(e) e.preventDefault();
    //     const a=get("/api/User/GetAllUsers")
    //     console.log({a});
    //     // .then((res) => {
    //     //     console.log(res.json());
    //     // });
    // }

    // getAllUsers();

    const history = useHistory();
    
    const registerUser=(e)=>{
        e.preventDefault();
        post('/user/signup',user).then((res)=>{
            console.log(res);
            history.push('/Login');
        });
    };

    return <>
        <div className="col-12">
            <div className="event-d-sidebar">
                <div className="event-book-form">
                    <div className="category-title">
                        <i className="bi bi-bookmark-check" /> <h4>Register</h4>
                    </div>
                    <form id="event-book" className="event-book" onSubmit={registerUser}>
                        <div className="primary-input-group">
                            <input type="text" id="e-name1" placeholder="Prefix" value={user.prefix} onChange={(e) => updateUser({ ...user, prefix: e.target.value })} />
                            <input type="text" id="e-name2" placeholder="First Name" value={user.firstName} onChange={(e) => updateUser({ ...user, firstName: e.target.value })} />
                            <input type="text" id="e-name3" placeholder="Last Name" value={user.lastName} onChange={(e) => updateUser({ ...user, lastName: e.target.value })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="text" id="e-spec" placeholder="Specialization" value={user.specialization} onChange={(e) => updateUser({ ...user, specialization: e.target.value })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="tel" id="e-tel" placeholder="Phone" value={user.mobile} onChange={(e) => updateUser({ ...user, mobile: e.target.value })} />
                        </div>
                        <div className="primary-input-group">
                            <input type="email" id="e-email" placeholder="Your Email" value={user.emailId} onChange={(e) => updateUser({ ...user, emailId: e.target.value })} />
                        </div>
                        <div className="primary-input-group">
                            <input id="pwd" placeholder="Password" type="password" value={user.password} onChange={(e) => updateUser({ ...user, password: e.target.value })} />
                            <input id="cpwd" placeholder="Retype Password" type="password" value={user.confirmPassword} onChange={(e) => updateUser({ ...user, confirmPassword: e.target.value })} />
                            {user.password !== user.confirmPassword ? "Passwords don't match" : ""}
                        </div>
                        <div className="submit-btn">
                            <button
                            onClick={registerUser}
                                type="submit"
                                className="primary-submit d-block w-100">
                                Register
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>;
}
